/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: { input: string; output: string; }
};

/** Auth type */
export enum AuthType {
  Session = 'SESSION',
  Token = 'TOKEN'
}

/** Autogenerated input type of CredentialsUserAuth */
export type CredentialsUserAuthInput = {
  authType?: InputMaybe<AuthType>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  rememberMe: Scalars['Boolean']['input'];
};

/** Autogenerated return type of CredentialsUserAuth. */
export type CredentialsUserAuthPayload = {
  __typename?: 'CredentialsUserAuthPayload';
  authToken?: Maybe<Scalars['String']['output']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  csrfToken: Scalars['String']['output'];
  user?: Maybe<User>;
};

/** Autogenerated input type of EmailTokenUserAuth */
export type EmailTokenUserAuthInput = {
  authType?: InputMaybe<AuthType>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  timeZone: Scalars['String']['input'];
  token: Scalars['String']['input'];
};

/** Autogenerated return type of EmailTokenUserAuth. */
export type EmailTokenUserAuthPayload = {
  __typename?: 'EmailTokenUserAuthPayload';
  authToken?: Maybe<Scalars['String']['output']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  csrfToken: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of EmailUserAuthChallenge */
export type EmailUserAuthChallengeInput = {
  clientAuthCode: Scalars['String']['input'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
};

/** Autogenerated return type of EmailUserAuthChallenge. */
export type EmailUserAuthChallengePayload = {
  __typename?: 'EmailUserAuthChallengePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of Logout */
export type LogoutInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** Autogenerated return type of Logout. */
export type LogoutPayload = {
  __typename?: 'LogoutPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  credentialsUserAuth: CredentialsUserAuthPayload;
  /** Authenticate a user with an email token */
  emailTokenUserAuth: EmailTokenUserAuthPayload;
  /** Send an email to the user with a challenge code */
  emailUserAuthChallenge: EmailUserAuthChallengePayload;
  logout: LogoutPayload;
  smsUserAuth: SmsUserAuthPayload;
  /** Send a sms to the user with a challenge code */
  smsUserAuthChallenge: SmsUserAuthChallengePayload;
  /** Updates a user by id */
  userUpdate: UserUpdatePayload;
};


export type MutationCredentialsUserAuthArgs = {
  input: CredentialsUserAuthInput;
};


export type MutationEmailTokenUserAuthArgs = {
  input: EmailTokenUserAuthInput;
};


export type MutationEmailUserAuthChallengeArgs = {
  input: EmailUserAuthChallengeInput;
};


export type MutationLogoutArgs = {
  input: LogoutInput;
};


export type MutationSmsUserAuthArgs = {
  input: SmsUserAuthInput;
};


export type MutationSmsUserAuthChallengeArgs = {
  input: SmsUserAuthChallengeInput;
};


export type MutationUserUpdateArgs = {
  input: UserUpdateInput;
};

/** An object with an ID. */
export type Node = {
  /** ID of the object. */
  id: Scalars['ID']['output'];
};

export type Query = {
  __typename?: 'Query';
  /** Fetches an object given its ID. */
  node?: Maybe<Node>;
  /** Fetches a list of objects given a list of IDs. */
  nodes: Array<Maybe<Node>>;
  viewer?: Maybe<User>;
};


export type QueryNodeArgs = {
  id?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryNodesArgs = {
  ids?: InputMaybe<Array<Scalars['ID']['input']>>;
};

/** Autogenerated input type of SmsUserAuthChallenge */
export type SmsUserAuthChallengeInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  phoneNumber: Scalars['String']['input'];
};

/** Autogenerated return type of SmsUserAuthChallenge. */
export type SmsUserAuthChallengePayload = {
  __typename?: 'SmsUserAuthChallengePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

/** Autogenerated input type of SmsUserAuth */
export type SmsUserAuthInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  timeZone: Scalars['String']['input'];
};

/** Autogenerated return type of SmsUserAuth. */
export type SmsUserAuthPayload = {
  __typename?: 'SmsUserAuthPayload';
  authToken?: Maybe<Scalars['String']['output']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type User = Node & {
  __typename?: 'User';
  createdAt: Scalars['ISO8601DateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  /** ID of the object. */
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['ISO8601DateTime']['output'];
  userRole: UserRole;
  userStatus: UserStatus;
};

export type UserInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  timeZone?: InputMaybe<Scalars['String']['input']>;
  userStatus?: InputMaybe<UserStatus>;
};

/** User role enum */
export enum UserRole {
  Default = 'DEFAULT',
  SystemAdmin = 'SYSTEM_ADMIN'
}

/** User status enum */
export enum UserStatus {
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
  Invited = 'INVITED'
}

/** Autogenerated input type of UserUpdate */
export type UserUpdateInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  userInput: UserInput;
};

/** Autogenerated return type of UserUpdate. */
export type UserUpdatePayload = {
  __typename?: 'UserUpdatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  user: User;
};

export type CachedViewerContextFragment = { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, userRole: UserRole };

export type LogoutMutationVariables = Exact<{
  input: LogoutInput;
}>;


export type LogoutMutation = { __typename?: 'Mutation', logout: { __typename?: 'LogoutPayload', success: boolean } };

export type ViewerQueryVariables = Exact<{ [key: string]: never; }>;


export type ViewerQuery = { __typename?: 'Query', viewer?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null, userRole: UserRole } | null };

export type LoginMutationVariables = Exact<{
  input: CredentialsUserAuthInput;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'CredentialsUserAuthPayload', user?: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null } | null } };

export type EmailTokenAuthMutationVariables = Exact<{
  input: EmailTokenUserAuthInput;
}>;


export type EmailTokenAuthMutation = { __typename?: 'Mutation', emailTokenUserAuth: { __typename?: 'EmailTokenUserAuthPayload', success: boolean, csrfToken: string } };

export type EmailAuthChallengeMutationVariables = Exact<{
  input: EmailUserAuthChallengeInput;
}>;


export type EmailAuthChallengeMutation = { __typename?: 'Mutation', emailUserAuthChallenge: { __typename?: 'EmailUserAuthChallengePayload', success: boolean } };

export type UpdateProfileMutationVariables = Exact<{
  input: UserUpdateInput;
}>;


export type UpdateProfileMutation = { __typename?: 'Mutation', userUpdate: { __typename?: 'UserUpdatePayload', user: { __typename?: 'User', id: string, firstName?: string | null, lastName?: string | null } } };

export type UserDetailsQueryVariables = Exact<{
  userId: Scalars['ID']['input'];
}>;


export type UserDetailsQuery = { __typename?: 'Query', node?: { __typename?: 'User', firstName?: string | null, lastName?: string | null, userRole: UserRole, fullName?: string | null, email?: string | null } | null };

export const CachedViewerContextFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CachedViewerContext"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"User"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"userRole"}}]}}]} as unknown as DocumentNode<CachedViewerContextFragment, unknown>;
export const LogoutDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"Logout"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"LogoutInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"logout"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}}]}}]}}]} as unknown as DocumentNode<LogoutMutation, LogoutMutationVariables>;
export const ViewerDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"Viewer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"viewer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"CachedViewerContext"}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"CachedViewerContext"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"User"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"userRole"}}]}}]} as unknown as DocumentNode<ViewerQuery, ViewerQueryVariables>;
export const LoginDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"Login"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"CredentialsUserAuthInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"login"},"name":{"kind":"Name","value":"credentialsUserAuth"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}}]}}]}}]}}]} as unknown as DocumentNode<LoginMutation, LoginMutationVariables>;
export const EmailTokenAuthDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"EmailTokenAuth"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"EmailTokenUserAuthInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"emailTokenUserAuth"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}},{"kind":"Field","name":{"kind":"Name","value":"csrfToken"}}]}}]}}]} as unknown as DocumentNode<EmailTokenAuthMutation, EmailTokenAuthMutationVariables>;
export const EmailAuthChallengeDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"EmailAuthChallenge"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"EmailUserAuthChallengeInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"emailUserAuthChallenge"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}}]}}]}}]} as unknown as DocumentNode<EmailAuthChallengeMutation, EmailAuthChallengeMutationVariables>;
export const UpdateProfileDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateProfile"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"input"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"UserUpdateInput"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"userUpdate"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"input"},"value":{"kind":"Variable","name":{"kind":"Name","value":"input"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"user"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}}]}}]}}]}}]} as unknown as DocumentNode<UpdateProfileMutation, UpdateProfileMutationVariables>;
export const UserDetailsDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"userDetails"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"userId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"node"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"userId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"User"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"firstName"}},{"kind":"Field","name":{"kind":"Name","value":"lastName"}},{"kind":"Field","name":{"kind":"Name","value":"userRole"}},{"kind":"Field","name":{"kind":"Name","value":"fullName"}},{"kind":"Field","name":{"kind":"Name","value":"email"}}]}}]}}]}}]} as unknown as DocumentNode<UserDetailsQuery, UserDetailsQueryVariables>;