/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "fragment CachedViewerContext on User {\n  id\n  firstName\n  lastName\n  userRole\n}": types.CachedViewerContextFragmentDoc,
    "\n  mutation Logout($input: LogoutInput!) {\n    logout(input: $input) {\n      success\n    }\n  }\n": types.LogoutDocument,
    "\n  query Viewer {\n    viewer {\n      id\n      ...CachedViewerContext\n    }\n  }\n": types.ViewerDocument,
    "\n  mutation Login($input: CredentialsUserAuthInput!) {\n    login: credentialsUserAuth(input: $input) {\n      user {\n        id\n        firstName\n        lastName\n      }\n    }\n  }\n": types.LoginDocument,
    "\n  mutation EmailTokenAuth($input: EmailTokenUserAuthInput!) {\n    emailTokenUserAuth(input: $input) {\n      success\n      csrfToken\n    }\n  }\n": types.EmailTokenAuthDocument,
    "\n  mutation EmailAuthChallenge($input: EmailUserAuthChallengeInput!) {\n    emailUserAuthChallenge(input: $input) {\n      success\n    }\n  }\n": types.EmailAuthChallengeDocument,
    "\n  mutation UpdateProfile($input: UserUpdateInput!) {\n    userUpdate(input: $input) {\n      user {\n        id\n        firstName\n        lastName\n      }\n    }\n  }\n": types.UpdateProfileDocument,
    "\n  query userDetails($userId: ID!) {\n    node(id: $userId) {\n      ... on User {\n        firstName\n        lastName\n        userRole\n        fullName\n        email\n      }\n    }\n  }\n": types.UserDetailsDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "fragment CachedViewerContext on User {\n  id\n  firstName\n  lastName\n  userRole\n}"): (typeof documents)["fragment CachedViewerContext on User {\n  id\n  firstName\n  lastName\n  userRole\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation Logout($input: LogoutInput!) {\n    logout(input: $input) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation Logout($input: LogoutInput!) {\n    logout(input: $input) {\n      success\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query Viewer {\n    viewer {\n      id\n      ...CachedViewerContext\n    }\n  }\n"): (typeof documents)["\n  query Viewer {\n    viewer {\n      id\n      ...CachedViewerContext\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation Login($input: CredentialsUserAuthInput!) {\n    login: credentialsUserAuth(input: $input) {\n      user {\n        id\n        firstName\n        lastName\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation Login($input: CredentialsUserAuthInput!) {\n    login: credentialsUserAuth(input: $input) {\n      user {\n        id\n        firstName\n        lastName\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation EmailTokenAuth($input: EmailTokenUserAuthInput!) {\n    emailTokenUserAuth(input: $input) {\n      success\n      csrfToken\n    }\n  }\n"): (typeof documents)["\n  mutation EmailTokenAuth($input: EmailTokenUserAuthInput!) {\n    emailTokenUserAuth(input: $input) {\n      success\n      csrfToken\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation EmailAuthChallenge($input: EmailUserAuthChallengeInput!) {\n    emailUserAuthChallenge(input: $input) {\n      success\n    }\n  }\n"): (typeof documents)["\n  mutation EmailAuthChallenge($input: EmailUserAuthChallengeInput!) {\n    emailUserAuthChallenge(input: $input) {\n      success\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation UpdateProfile($input: UserUpdateInput!) {\n    userUpdate(input: $input) {\n      user {\n        id\n        firstName\n        lastName\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation UpdateProfile($input: UserUpdateInput!) {\n    userUpdate(input: $input) {\n      user {\n        id\n        firstName\n        lastName\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query userDetails($userId: ID!) {\n    node(id: $userId) {\n      ... on User {\n        firstName\n        lastName\n        userRole\n        fullName\n        email\n      }\n    }\n  }\n"): (typeof documents)["\n  query userDetails($userId: ID!) {\n    node(id: $userId) {\n      ... on User {\n        firstName\n        lastName\n        userRole\n        fullName\n        email\n      }\n    }\n  }\n"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;